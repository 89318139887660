import moment from "moment";
import React from "react";
import {
  BulkInvitation,
  CorporateInvitation,
} from "../../Interfaces/Invitations";

import * as InvitationCardStyle from "./InvitationCard.module.scss";

interface Props {
  name: string;
  eventFrom: string;
  eventTo: string;
  maxStrength?: string;
  status: string;
  amount: string;
  onClickOpenEvent: () => void;
}

const Index = ({
  amount,
  eventFrom,
  eventTo,
  maxStrength,
  name,
  status,
  onClickOpenEvent,
}: Props) => {
  return (
    <div className={InvitationCardStyle.card}>
      <div className={`${InvitationCardStyle.card__header} grediant1`}>
        <h6 className="textMd">{name}</h6>
      </div>
      <div className={InvitationCardStyle.card__content}>
        <div className={InvitationCardStyle.card__content__item}>
          <span>Date</span>
          <span>{moment(eventFrom).format("DD/MM/YYYY hh:mm")}</span>
          <span>{moment(eventTo).format("DD/MM/YYYY hh:mm")}</span>
        </div>
        {maxStrength && (
          <div className={InvitationCardStyle.card__content__item}>
            <span>Parking for number of card </span>
            <span>{maxStrength}</span>
          </div>
        )}

        <div className={InvitationCardStyle.card__content__item}>
          <span>Status</span>
          <span>{status}</span>
        </div>
        <div className={InvitationCardStyle.card__content__item}>
          <span>Amount</span>
          <span> &#8377;{amount}</span>
        </div>
        <div className={InvitationCardStyle.card__content__btn}>
          <button onClick={onClickOpenEvent}>Open Event</button>
        </div>
      </div>
    </div>
  );
};

export default Index;
