import React, { useEffect, useState } from "react";

import { navigate } from "gatsby";
import { useQuery } from "@apollo/client";
import { Downgraded, useState as useHookState } from "@hookstate/core";

import { CorporateInviteQuery } from "../../Query/Invitations";

import InvitationCard from "../InvitationCard";
import Loader from "../Loader";

import AppState from "../../States/AppState";

import Logger from "../../utils/Logger";

import {
  CorporateInvitation,
  CorporateInvitationQuery,
} from "../../Interfaces/Invitations";

import * as CorporateInvitationStyle from "./CorporateInvitation.module.scss";

const Index = () => {
  const user = useHookState(AppState).attach(Downgraded).user.get();
  const [page, setPage] = useState(1);

  const [corporateInvitation, setCorporateInvitation] =
    useState<CorporateInvitation[]>();

  const {
    data: invitationData,
    loading,
    error,
    fetchMore,
    refetch,
  } = useQuery<CorporateInvitationQuery>(CorporateInviteQuery, {
    variables: {
      user: parseInt(user?.id?.split("/")[2] || ""),
      status: ["APPROVED"],
      order: [{ createdAt: "DESC" }],
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      Logger.showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (invitationData?.privateInvitations.collection) {
      setCorporateInvitation(
        corporateInvitation
          ? [
              ...corporateInvitation,
              ...invitationData.privateInvitations.collection,
            ]
          : invitationData.privateInvitations.collection
      );
    }
  }, [invitationData]);

  return (
    <>
      <div className={CorporateInvitationStyle.container}>
        <div>
          <h5 className="boldText color-primary textMd">
            Corporate Invitation List
          </h5>
        </div>
        <div className={CorporateInvitationStyle.content}>
          {!invitationData?.privateInvitations.collection.length &&
            !loading && (
              <h6 className="boldText color-primary textMd">
                No Corporate Invitation
              </h6>
            )}
          {invitationData?.privateInvitations.collection.map(
            ({ id, amount, status, parkingArea, bookFrom, bookTo }) => (
              <InvitationCard
                key={id}
                name={parkingArea?.name}
                eventFrom={bookFrom}
                eventTo={bookTo}
                amount={amount}
                status={status.code}
                onClickOpenEvent={() => {
                  navigate(`/corporateinvitation/${id.split("/")[2]}`);
                }}
              />
            )
          )}
          {invitationData?.privateInvitations &&
            invitationData.privateInvitations.paginationInfo.lastPage >
              page && (
              <div className={CorporateInvitationStyle.load__more}>
                <button
                  onClick={() => {
                    fetchMore({
                      variables: {
                        page: page + 1,
                      },
                    })
                      .then((res) => {
                        setPage(page + 1);
                        setCorporateInvitation(
                          corporateInvitation
                            ? [
                                ...corporateInvitation,
                                ...res.data.privateInvitations.collection,
                              ]
                            : res.data.privateInvitations.collection
                        );
                      })
                      .catch((err) => {
                        Logger.showError(err);
                      });
                  }}
                >
                  Load More
                </button>
              </div>
            )}
        </div>
      </div>
      <Loader isLoading={loading} />
    </>
  );
};

export default Index;
