import React from "react";

import Layout from "../Components/Layout";
import CorporateInvitation from "../Components/CorporateInvitation";

const CorporateinvitationPage = () => {
  return (
    <Layout isPrivate showHeader>
      <CorporateInvitation />
    </Layout>
  );
};

export default CorporateinvitationPage;
